import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input, OnChanges, OnInit
} from '@angular/core';
import {Article} from '../../../../shared/models/Article';
import {BehaviorSubject} from 'rxjs';
import {slugifyString} from '@common/core/utils/slugify-string';
import {ActivatedRoute, Router} from '@angular/router';
import {delay} from 'rxjs/operators';
import {stripTags} from '@common/core/utils/strip-tags';

interface ContentItem {
    indent: boolean;
    content: string;
    type: string;
}

@Component({
    selector: 'article-content',
    templateUrl: './article-content.component.html',
    styleUrls: ['./article-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleContentComponent implements OnChanges, OnInit {
    @Input() article: Article;

    public contentItems$ = new BehaviorSubject<ContentItem[]>([]);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.route.fragment
            .pipe(delay(10))
            .subscribe(fragment => {
                this.scrollContentIntoView(fragment);
            });
    }

    ngOnChanges() {
        const matches: ContentItem[] = [];
        const regExp = /<(h[2|3]) id=".*?">(.*?)<\/h[2|3]>/g;
        let match = regExp.exec(this.article.body);
        while (match != null) {
            let heading = match[2];
            const type = match[1];
            if (heading.indexOf('<a') === 0) {
                heading = heading.match(/<a href=".*?">(.*?)<\/a>/)[1];
            }
            const indent = type === 'h3' && matches.some(m => m.type === 'h2');
            matches.push({content: stripTags(heading), indent, type});
            match = regExp.exec(this.article.body);
        }
        this.contentItems$.next(matches || []);
    }

    public scrollContentIntoView(contentItem: string) {
        if (contentItem) {
            const el = document.getElementById(contentItem) as HTMLElement;
            if (el) {
                window.scroll({ top: el.offsetTop, behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }

    public slug(contentItem: string) {
        return slugifyString(contentItem);
    }

    public onContentItemClick(contentItem: string) {
        // if this fragment is already present in url angular fragment change
        // will not trigger, so we need to manually scroll content item into view
        const slug = this.slug(contentItem);
        if (this.route.snapshot.fragment === slug) {
            this.scrollContentIntoView(slug);
        }
    }
}
