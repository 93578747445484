import {Injectable} from '@angular/core';
import {BootstrapData, Bootstrapper} from '@common/core/bootstrapper.service';
import {MailboxTagsService} from './ticketing/mailbox-tags.service';
import {Tag} from '@common/core/types/models/Tag';

interface BedeskBootstrapData extends BootstrapData {
    tags: Tag[];
}

@Injectable({
    providedIn: 'root',
})
export class BedeskBootstrapper extends Bootstrapper {
    protected handleData(encodedData: string): BedeskBootstrapData {
        const data = super.handleData(encodedData) as BedeskBootstrapData;

        this.injector.get(MailboxTagsService).setTags(data.tags);

        // only show notification settings to agents
        this.settings.set('notif.subs.integrated', this.currentUser.hasPermission('tickets.view'));

        return data;
    }
}
